<template>
  <div class="w-full">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">Location Staff Records</h2>
    </div>

    <api-table
      :apiUrl="`/setup/office/${this.$route.params.id}`"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="name" slot-scope="props">
        {{ props.rowData.firstName }} {{ props.rowData.middleName }}
        {{ props.rowData.lastName }}
      </template>

      <template slot="action" slot-scope="props">
        <span class="flex gap-4 pr-2">
          <button
            class="px-4 rounded bg-red-500 text-white text-xs"
            @click="deleteStaff([props.rowData.employeeId])"
          >
            Remove
          </button>
          <router-link
            :to="{
              name: 'Staff Clockin History',
              params: { lid: $route.params.id, sid: props.rowData.employeeId },
            }"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            Clockin Stats
          </router-link>
          <router-link
            :to="{
              name: 'List Staff Clockin History',
              params: {
                sid: props.rowData.employeeId,
                sname: props.rowData.firstName + ' ' + props.rowData.lastName,
              },
            }"
            class="bg-idBlued text-center text-white text-xs rounded py-1 px-3"
          >
            View History
          </router-link>
          <!-- <router-link
            :to="{
              name: 'List Staff Clockin History',
              params: { sid: props.rowData.employeeId },
            }"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            View History
          </router-link> -->
        </span>
      </template>
    </api-table>
  </div>
</template>

<script>
export default {
  name: "LocationStaffs",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "verificationCode",
          title: "Verification Number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "salaryGradeLevel",
          title: "Grade Level",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "lastClockInDate",
          title: "Last Clock-in",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("MMM DD. YY hh:mm:ss"),
        },
        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    async deleteStaff(arr) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to remove this staff(s) from this location?",
          showDenyButton: true,
          confirmButtonText: "YES",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.delete("setup/remove-staff", {
          data: { employeeId: arr, officeAddressId: this.$route.params.id },
        });

        if (!res) {
          return;
        }

        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: "Staff Removed Successfully",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>
