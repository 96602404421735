<template>
  <div class="dropdown">
    <button
      class="flex items-center justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
      type="button"
      @click="toggleDropdown"
    >
      <span class="flex gap-3">
        <slot name="button-content"></slot>
      </span>

      <span
        class="text-[11px] text-gray-400 pr-4 pb-1 icon"
        :class="{ rotated: isOpen }"
      >
        &#x25BC;
      </span>
    </button>

    <div class="pl-2.5 dropdownDivider" :class="{ show: isOpen }" tabindex="0">
      <slot name="dropdown-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomDropdown",

  props: {
    // Allow parent to control open state if needed
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: this.modelValue,
    };
  },

  watch: {
    modelValue(newVal) {
      this.isOpen = newVal;
    },
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$emit("update:modelValue", this.isOpen);
    },

    handleClickOutside(event) {
      if (!event.target.closest(".dropdown")) {
        this.isOpen = false;
        this.$emit("update:modelValue", false);
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.dropdown {
  @apply border-none;
}

.dropdown li {
  @apply border-r-0 border-t-0 border-b-0 border-litgray rounded-none mt-2;
}

.dropdownDivider {
  display: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.dropdownDivider.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.icon {
  transition: transform 0.3s ease;
}

.icon.rotated {
  transform: rotateX(180deg);
}
</style>
